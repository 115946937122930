@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-body text-paragraph leading-6 bg-gray-200;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-white font-primary;
  }
  .section {
    @apply py-12 lg:py-32;
  }
  .section-title {
    @apply text-3xl lg:text-4xl font-medium lg:font-extrabold mb-5;
  }
  .subtitle {
    @apply text-base text-paragraph mb-12 lg:mb-16 max-w-[520px];
  }
  .btn {
    @apply py-4 px-7 font-medium text-white flex items-center justify-center rounded-sm hover:bg-accent-hover transition-all;
  }
  .btn-lg {
    @apply h-[54px];
  }
  .btn-md {
    @apply h-[48px];
  }
  .input {
    @apply bg-secondary text-paragraph h-[60px] outline-none pl-6 w-full font-body text-[15px] rounded-sm focus:outline focus:outline-1 focus:outline-accent;
  }
  .textarea {
    @apply bg-secondary resize-none w-full outline-none p-6 rounded-sm h-[200px] focus:outline focus:outline-1 focus:outline-accent;
  }
  .active {
    @apply text-accent;
  }
}

.project-image-container {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.project-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
}

.modal-image-container {
  position: relative;
}

.nav-item {
  flex: 1;
  border: 1px solid black;
}

nav ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  padding: 0;
  margin: 0;
  list-style: none;
}


/* category buttons */
.category-buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #131419; 
  border-radius: 64px; /* Bordes redondeados del contenedor */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Sombra del contenedor */
}



.category-button {
  cursor: pointer;
  padding: 0.1rem 1.5rem;
  border: 1px solid #1e90ff;
  border-radius: 9999px;
  background-color: transparent;
  color: #1e90ff;
  text-align: center;
  text-transform: capitalize;
  transition: all 0.3s;
  max-width: 150px; /* Define un ancho máximo para los botones */
  margin: 4px; /* Ajusta el margen entre botones */
}


.category-button:hover,
.category-button.active {
  background-color: #1e90ff;
  color: #ffffff;
}


.project-animation-enter {
  opacity: 0;
  transform: translateY(30px);
}

.project-animation-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}

.project-animation-exit {
  opacity: 1;
  transform: translateY(0);
}

.project-animation-exit-active {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 500ms, transform 500ms;
}

.project-animation {
  display: contents;
}

.category-buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
}


/* SELECT */ 

.custom-select {
  display: inline-block;
}

.select-wrapper {
  position: relative;
  display: inline-block;
  border: 2px solid #1e90ff;
  border-radius: 4px;
  background-color: transparent;
  font-size: 14px;
}

.custom-select select {
  display: inline-block;
  padding: 8px 12px;
  padding-right: 24px;
  border: none;
  border-radius: 4px;
  appearance: none;
  background-color: transparent;
  font-size: 14px;
  color: #1e90ff; /* Cambia el color del texto a blanco */
  text-transform: capitalize;
}

.select-arrow {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #ffffff; /* Cambia el color de la flecha a blanco */
  pointer-events: none;
}

.select-container {
  display: none;
}

@media (max-width: 600px) {
  .category-buttons-container {
    display: none;
  }
  .select-container {
    display: block;
  }
}

/* CBA */

/*##### HERO SECTION #####*/

.hero{
  height: 100%;
  background: url('https://source.unsplash.com/a2NRu2Wxa2o/');
  background-size: cover;
  background-position: center;
  
  .content{
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    
    .header{
      position: absolute;
      top: 55%;
      width: 100%;
      -webkit-transform: translate(0%, -50%);
      transform: translate(0%, -50%);
      text-align: center;
      opacity: 0;
      
      h1{
        color: #fff;
        font-size: 2.5em;
        text-shadow: 0px 2px 3px #2d2d2d;
      }
    }
  }
}

.hero-carousel .carousel-slider .slide img {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  filter: blur(3px);
  
}
